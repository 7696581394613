.k_wrapper {

    button {
        &.payment {
            border: none;
            box-shadow: none;
            border-radius: 5px;
            width: 80%;
            margin: 25px auto;
            padding: 0;
            display: block;
            line-height: 0;
            color: $black;
            height: 44px;

            &.paypal {
                background: #ffc001;

                img {
                    width: 132px;
                    padding: 8px;
                }
            }

            &.amazon {
                background: #fed464;
                margin-bottom: 35px;

                img {
                    width: 132px;
                    padding: 14px 8px 8px;
                }
            }

            &.loading::after {
                background: $green;
            }
        }

        &.paymentoption {
            background: none;
            border: none;
            box-shadow: none;
            color: $basetext;
            text-align: left;
            width: auto;
            display: block;
            margin: 20px 0;
            padding: 5px 0 5px 40px;
            position: relative;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 30px;
                height: 30px;
                @extend .kborderimage;
                border-width: 5px;
            }

            &.activ {
                &::after {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    content: '';
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background: $white;
                    box-shadow: 0 0 0 8px $green;
                }
            }

            img {
                width: auto;
                height: 20px;
                margin-left: 10px;
            }
        }
    }

    label {
        font-size: 18px;
        margin-top: 20px;
        display: block;

        &.checkbox {
            text-align: left;
            font-size: 18px !important;
            padding: 0 !important;

            &::after,
            &::before {
                display: none !important
            }

            a {
                color: $basetext;
            }
        }
    }

    .forminputholder {
        position: relative;

        &.checkbox {
            display: flex;
        }

        input {
            @extend .kborderimage;
            border-width: 5px;
            width: 100%;
            margin: 5px auto 10px;
            padding: 10px 20px;
            text-align: center;

            &.checkbox {
                width: 20px;
                height: 20px;
                float: left;
                margin: 20px 20px 0 0;
                opacity: 1;
                position: relative;
                display: block;
                z-index: 10;
            }
        }

        .inputinfobox {
            @extend .kborderimage;
            border-width: 5px;
            position: absolute;
            top: calc(100% - 20px);
            width: 80%;
            left: 10%;
            background: #fff;
            padding: 10px 15px;
            font-size: 16px;
            line-height: 20px;

            &::before {
                content: "";
                position: absolute;
                border-bottom: 8px solid #4a545b;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                top: -10px;
                left: calc(50% - 4px);
            }

            strong {
                font-weight: bold;
                margin-bottom: 5px;
                display: block;
            }
        }

    }

    .dataprotection {
        margin: 50px 0;

        &.small {
            font-size: 14px;
            margin: 20px 0 -20px;

            strong {
                font-weight: bold;
            }

            a {
                color: $basetext;
            }

        }
    }

    .paymentselection {
        margin: 50px 0;
    }

    .products {
        margin-top: 20px;

        .product {
            border-top: 1px solid $grey;
            padding: 20px 0;

            .left,
            .right {
                display: inline-block;
                width: 50%;
                margin: 5px 0;
            }

            .left {
                font-weight: bold;
            }

            .inputholder {
                display: inline-flex;

                input {
                    padding: 5px 0;
                }
            }

            .name {
                width: 80%;
                margin-bottom: 20px;

                &.overview {
                    width: 70%;
                    margin-bottom: 0;
                }
            }

            .close {
                width: 20%;
            }

            .sum {
                font-weight: bold;

                &.overview {
                    width: 30%;
                }
            }

            .small {
                font-size: 14px;
                font-weight: normal
            }

        }
    }

    .overviewbox {
        @extend .kborderimage;
        border-width: 5px;
        padding: 10px 25px;
        margin: 20px 0 30px;

        .left,
        .right {
            display: inline-block;
            width: 50%;
            margin: 5px 0;
        }

        .sum {
            font-weight: bold;
            font-size: 18px;
            margin: 20px 0;
        }
    }

    .submitbox {
        padding-top: 10px;
        margin-top: 20px;
        position: relative;

        .error {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: red;
            text-align: center;
        }
    }

    .pbox {
        width: 100%;
        height: 200px;
        margin-bottom: 20px;
    }


}