.k_wrapper {

    .cropperview {

        .cropper {
            height: calc(100vh - 90px);
            position: absolute;
            top: 90px;
            left: 0;
            width: 100%;
            background: #fff;
            z-index: 10;
            .cropperholder {
                position: relative;
            }

        }

        .reactEasyCrop_Container {
            position: relative;
            background: #fff;
            z-index: 10;
            min-height: 400px;
        }

        .action-btns {
            position: relative;
            z-index: 10;
        }

    }

}