.k_wrapper {
    .header {
        position: relative;
        height: 80px;
        margin-bottom: 20px;
        .close {
            background: url(#{$dataurl}/karls/close.png) no-repeat left center;
            width: 40px;
            height: 40px;
            display: inline-block;
            background-size: 100% auto;
            margin-top: 20px;
            cursor: pointer;
        }
        .logo {
            background: url(#{$dataurl}/karls/logo.png) no-repeat left center;
            width: 150px;
            height: 50px;
            display: inline-block;
            position: absolute;
            top: 15px;
            left: calc(50% - 75px);
            background-size: 100% auto;
        }
        .back {
            float: right;
            position: relative;
            padding-left: 25px;
            font-size: 14px;
            line-height: 20px;
            margin-top: 30px;
            text-decoration: none;
            color: $basetext;            
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                background: url(#{$dataurl}/karls/small-arrow.png) no-repeat left center;
                background-size: 100% auto;
                width: 20px;
                height: 20px;
                display: inline-block;
                transform: rotate(90deg);
            }
        }
    }
}