@font-face {
    font-display: block; 
    font-family: 'Hammersmith One';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/hammersmith-one-v17-latin-regular.eot'); 
    src: url('../fonts/hammersmith-one-v17-latin-regular.eot?#iefix') format('embedded-opentype'), 
         url('../fonts/hammersmith-one-v17-latin-regular.woff2') format('woff2'), 
         url('../fonts/hammersmith-one-v17-latin-regular.woff') format('woff'), 
         url('../fonts/hammersmith-one-v17-latin-regular.ttf') format('truetype'), 
         url('../fonts/hammersmith-one-v17-latin-regular.svg#HammersmithOne') format('svg'); 
  }
  