.k_wrapper {
    .navbox {
        display: block;
        position: relative;
        width: calc(100% + 20px);
        margin: 0 0 10px -20px;
        img {
            position: relative;
            z-index: 1;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: 0;
            top: 22%;
            right: 1%;
            width: 93%;
            height: 77%;
            box-shadow: 0 0 30px 20px $shadow;
            border-radius: 20px;
        }
    }

    .productbox {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-bottom: 30px;
        box-shadow: 0 0 30px 20px $shadow;
        border-radius: 20px;
        padding: 15px 25px;

        .toggle {
            position: absolute;
            top: 15px;
            left: 15px;
            width: 30px;
            height: 20px;
            display: block;
            background: url(#{$dataurl}/karls/small-arrow.png) no-repeat center;
            background-size: 100% auto;
            transition: all .3s linear;
            cursor: pointer;
        }

        .name, .nameadd, .price {
            display: block;
            font-size: 18px;
            line-height: 24px;
        }

        .name, .nameadd {
            padding: 0 25px;
        }

        .price {
            color: $green;
            font-weight: bold; 
            font-size: 20px;    
            margin-top: 5px;       
        }    
        
        .nocontingent {
            color: #9C2525;
            font-weight: bold;
            text-transform: uppercase;
            margin: 15px 0 5px;
            display: block;
        }

        .togglecont {
            max-height: 0;         
            overflow: hidden; 
            transition: max-height .5s ease; 
            img {
                border-radius: 10px;
                margin: 10px 0 20px;
            }
            p {
                font-size: 14px;
                margin: 0;
            }
        }

        &.open {
            .toggle {
                transform: rotate(180deg);
            }            
            .togglecont{
                max-height: 500px;
            }
        }
    }

    .inputholder {
        @extend .kborderimage;
        position: relative;
        width: 50%;
        margin: 20px auto 10px;
        display: flex;
        justify-content: center;
        align-items: center;            
        border-width: 5px;
        input {
            width: 30%;                
            text-align: center;
            padding: 20px 0;
            font-size: 18px;
        }
        .up, .down {            
            text-align: center;   
            font-size: 40px;
            width: 35%;
            height: 100%;
            font-family: "Hammersmith One", serif;
            cursor: pointer;
        }
    }


}