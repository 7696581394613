// image-domain
$dataurl: "https://dev-karls-ticketshop-server.digiwerft.de";
 
// colors

$black: #000;
$white: #fff;
$grey: #cdcdcd;
$green: #65b901;
$basetext: #343434;
$shadow: #ecefe8;
  